import { ReferenceInput, required, SaveButton, SelectInput, TextInput, Toolbar } from "react-admin";
import EditBase from "../BaseModel/Edit";

const UpdateToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton
      transform={(data) => {
        const { description, name, type, connection, parameters } = data;
        return {
          description,
          name,
          type,
          connection,
          parameters,
        };
      }}
      submitOnEnter={false}
    />
  </Toolbar>
);
const sort = { field: "name", order: "ASC" };
const EditForm = (props: any) => (
  <EditBase {...props} toolbar={<UpdateToolbar />}>
    <ReferenceInput source="type" reference="integrationType" validate={[required()]}>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="parameters.connection"
      reference="connection"
      validate={[required()]}
      sort={sort}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput source="parameters.path" />
    <TextInput source="parameters.file_name" />
    <TextInput source="parameters.content_pattern" />
  </EditBase>
);

export default EditForm;
