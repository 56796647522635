import { DateField, ReferenceField, TextField } from "react-admin";
import ShowBase from "../BaseModel/Show";

const ShowForm = (props: any) => (
  <ShowBase {...props}>
    <ReferenceField source="type" reference="integrationType" link="show">
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField source="parameters.connection" reference="connection" link="show">
      <TextField source="name" />
    </ReferenceField>
    <TextField source="parameters.path" />
    <TextField source="parameters.file_name" />
    <TextField source="parameters.content_pattern" />
    <ReferenceField source="created_by" reference="user" link="show">
      <TextField source="name" />
    </ReferenceField>
    <DateField source="created_at" showTime={true} />
    <ReferenceField source="updated_by" reference="user" link="show">
      <TextField source="name" />
    </ReferenceField>
    <DateField source="updated_at" showTime={true} />
  </ShowBase>
);

export default ShowForm;
