import {
  TextField,
  ReferenceField,
  BooleanField,
  DateField,
  NullableBooleanInput,
  ReferenceInput,
  SelectInput,
  ArrayField,
  Datagrid,
  TopToolbar,
  FilterButton,
  CreateButton,
  ExportButton,
} from "react-admin";
import ListBase from "../BaseModel/List";
import TestButton from "../../CustomTestAction/TestAction";
import { authorizeAction } from "@/Settings/roles";
import { useExploreActions } from "..";
import ExploreConnectionButton from "./ExploreConnectionButton";

const ListTopToolBar = (props: any) => {
  return (
    <TopToolbar>
      <FilterButton />
      <CreateButton />
      <ExportButton />
      {props.useExplore && <ExploreConnectionButton label="Explore" />}
    </TopToolbar>
  );
};
const ListForm = (props: any) => {
  const exploreConnectionAction =
    useExploreActions(props.resource) &&
    authorizeAction(props.options.roles, props.resource, "explore");
  const filters = [
    <NullableBooleanInput helperText={false} source="enabled" />,
    <ReferenceInput source="type" reference="connectionType">
      <SelectInput optionText="name" />
    </ReferenceInput>,
  ];
  return (
    <ListBase
      actions={<ListTopToolBar useExplore={exploreConnectionAction} />}
      filters={filters}
      {...props}
    >
      <BooleanField source="enabled" sortable={false} />
      <ReferenceField source="type" reference="connectionType" link="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="created_by" reference="user" link="show">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="created_at" showTime={true} />
      <ReferenceField source="updated_by" reference="user" link="show">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="updated_at" showTime={true} />
      <BooleanField source="maintenance_enabled" sortable={false} />
      <ArrayField source="maintenance_schedules">
        <Datagrid>
          <TextField source="start_schedule" />
          <TextField source="end_schedule" />
        </Datagrid>
      </ArrayField>
      {authorizeAction(props.options.roles, props.resource, "test") && <TestButton />}
    </ListBase>
  );
};

export default ListForm;
