import Icon from "@material-ui/icons/CompareArrows";
import List from "./List";
import Show from "./Show";

const Migration = {
    list: List,
    show: Show,
    icon: Icon,
};

export default Migration;