import { makeStyles } from "@material-ui/core/styles";
const green = "#caffca";
const red = "#ff7171";
const gray = "#9f9f9f";
const yellow = "yellow";
const orange = "orange";
const darkgray = "#DDDDDD";
const cellStyles = {
  display: "block",
  padding: "12px",
  borderRadius: "4px",
  margin: "-5px",
  color: "black",
  fontWeight: 600,
};

const ListCss = makeStyles((theme) => ({
  withoutColor: {
    ...cellStyles,
    color: "unset",
  },
  green: {
    ...cellStyles,
    backgroundColor: green,
  },
  red: {
    ...cellStyles,
    backgroundColor: red,
  },
  gray: {
    ...cellStyles,
    backgroundColor: gray,
  },
  yellow: {
    ...cellStyles,
    backgroundColor: yellow,
  },
  orange: {
    ...cellStyles,
    backgroundColor: orange,
  },
  darkgray: {
    ...cellStyles,
    backgroundColor: darkgray,
  },
}));

const FilesListCss = makeStyles((theme) => ({
  div: {
    width: "80%",
    border: theme.palette.type === "dark" ? "1px solid #575757" : "1px solid #e9e9e9",
    borderRadius: "6px",
    margin: "18px auto",
  },
  textColor: {
    color: "black",
    fontWeight: 600,
  },
  withoutColor: {
    backgroundColor: "unset",
  },
  green: {
    backgroundColor: green,
  },
  red: {
    backgroundColor: red,
  },
  gray: {
    backgroundColor: gray,
  },
  yellow: {
    backgroundColor: yellow,
  },
  orange: {
    backgroundColor: orange,
  },
  darkgray: {
    backgroundColor: darkgray,
  },
}));

export { ListCss, FilesListCss };
