import {
  TextField,
  NumberField,
  List,
  Datagrid,
  useTranslate,
  UrlField,
  usePermissions,
} from "react-admin";
import SearchBarForm from "./SearchBar";
import FileList, { disabledSelectionFilesStatus, ExecutionFiles } from "./FilesList";
import { ListCss } from "./Styles/List.css";
import Checkbox from "@material-ui/core/Checkbox";
import OpenLogsButton from "../../CustomOpenLogsAction/OpenLogsAction";
import { useSharedListChecked } from "@/Components/Models/Tracking/SharedState";
import { authorizeAction } from "@/Settings/roles";

const NumberFieldColorCondition = (data: any) => {
  const listCss = ListCss();
  return (
    <NumberField
      {...data}
      className={data.record[data.source] > 0 ? data.className : listCss.withoutColor}
    />
  );
};

const ConfigurationLink = (data: any) => {
  const link = "/#/configuration/" + data.record.configurationId;
  return (
    <UrlField {...data} href={link} target="_blank" rel="noopener noreferrer">
      {data.source}
    </UrlField>
  );
};

const ExecutionCheckBox = (data: any) => {
  const { listChecked, updateListChecked } = useSharedListChecked();

  const ExecutionSelection = (event: any, executionId: string) => {
    const fileMetaDataArray = ExecutionFiles.get(executionId)?.filter(
      (file) => !disabledSelectionFilesStatus.includes(file.fileStatus)
    );
    const ids = fileMetaDataArray?.map((file) => file.fileId) || [];

    if (event.target.checked) {
      updateListChecked([...listChecked, ...ids]);
    } else {
      updateListChecked(listChecked.filter((element) => !ids.includes(element)));
    }
  };
  return (
    <Checkbox
      disabled={data.record.mergedFiles?.length === 0}
      id={data.record.id}
      onChange={(e) => ExecutionSelection(e, data.record.id)}
    />
  );
};

const ListForm = (props: any) => {
  const listCss = ListCss();
  const translate = useTranslate();
  const { permissions, loading } = usePermissions();
  return loading ? (
    <p>Loading</p>
  ) : (
    <List
      {...props}
      bulkActionButtons={false}
      pagination={false}
      sort={{ field: "endDateLastFlow", order: "DESC" }}
      actions={<SearchBarForm permissions={permissions} />}
    >
      <Datagrid expand={FileList}>
        <ExecutionCheckBox></ExecutionCheckBox>
        <ConfigurationLink
          source="configurationName"
          label={translate("resources.tracking.searchForm.results.configurationName")}
        />
        <ConfigurationLink
          source="configurationCode"
          label={translate("resources.tracking.searchForm.results.configurationCode")}
        />
        <NumberFieldColorCondition
          align="center"
          className={listCss.green}
          source="terminatedInstances"
        />
        <NumberFieldColorCondition align="center" className={listCss.red} source="errorInstances" />
        <NumberFieldColorCondition
          align="center"
          className={listCss.orange}
          source="InProgressInstances"
        />
        <NumberFieldColorCondition
          align="center"
          className={listCss.yellow}
          source="abandonedInstances"
        />
        <NumberFieldColorCondition
          align="center"
          className={listCss.darkgray}
          source="cancelledInstances"
        />
        <NumberFieldColorCondition
          align="center"
          className={listCss.orange}
          source="pendingFiles"
        />
        <TextField align="center" source="waitingTimeInProgressFiles" />
        <TextField align="left" source="endDateLastFlow" />
        {authorizeAction(permissions, props.resource, "log") ? <OpenLogsButton /> : <></>}
      </Datagrid>
    </List>
  );
};

export default ListForm;
