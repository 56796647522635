import { useCallback, useEffect, useRef, useState } from "react";
import { Record, useListContext, usePermissions, useTranslate } from "react-admin";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import { FilesListCss } from "./Styles/List.css";
import OpenLogsButton from "../../CustomOpenLogsAction/OpenLogsAction";
import DownloadButton from "../../CustomDownloadFileAction/DownloadFileAction";
import { useSharedListChecked } from "@/Components/Models/Tracking/SharedState";
import { authorizeAction, authorizeDownload } from "@/Settings/roles";
import API from "@aws-amplify/api-rest";
import { CircularProgress } from "@mui/material";

export interface TrackingFileData {
  id: string;

  // execution data
  executionCorrelationId: string;
  executionStartDateTimestamp?: number;
  executionEndDateTimestamp?: number;
  executionStartDate?: string;
  executionEndDate?: string;

  // file data
  fileName: string;
  progression?: string;
  technicalProgression?: string;
  fileStartDateTimestamp?: number;
  fileEndDateTimestamp?: number;
  fileStartDate?: string;
  fileEndDate?: string;
  errorDetails?: string;

  // targets
  targetName?: string;
}

const calculateColorByStatus = (
  progression: string,
  filesListCss: ReturnType<typeof FilesListCss>
) => {
  switch (progression) {
    case "ENDED":
      return filesListCss.green;
    case "ERROR":
      return filesListCss.red;
    case "CANCELLED":
      return filesListCss.darkgray;
    case "ABANDONED":
      return filesListCss.yellow;
    case "IN_PROGRESS":
      return filesListCss.orange;
    default:
      return filesListCss.gray;
  }
};

export const disabledSelectionFilesStatus = ["ENDED", "ABANDONED"];

export interface FileMetaData {
  fileId: string;
  executionCorrelationId: string;
  executionStartDateTimestamp: number;
  fileName: string;
  fileStatus: string;
  targets: number[];
}

export const ExecutionFiles = new Map<string, FileMetaData[]>();

const InitFiles = (props: any) => {
  const { record, data } = props;
  const correlationId = record.id;

  const fileTargets = (fileTarget: string | null): number[] => {
    if (fileTarget !== null) {
      return [Number(fileTarget)];
    }
    return record.targets
      ? Object.keys(record.targets).map((targetId: string) => Number(targetId))
      : [];
  };
  let fileMetaData: FileMetaData[] = [];
  fileMetaData = data.map((file: TrackingFileData) => {
    return {
      fileId: file.id,
      executionCorrelationId: file.executionCorrelationId,
      executionStartDateTimestamp: file.executionStartDateTimestamp,
      fileName: file.fileName,
      fileStatus: file.progression,
      targets: fileTargets(file.targetName || null),
    };
  });
  ExecutionFiles.set(correlationId, fileMetaData);
  return <></>;
};

const FileList = ({ record }: Record) => {
  const { configurationCode, configurationName } = record;

  const { filterValues } = useListContext();
  const [rows, setRows] = useState(new Array<any>());
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [hasNextPage, setNextPage] = useState(true);
  const { permissions, loading } = usePermissions();
  const { listChecked, updateListChecked } = useSharedListChecked();
  const translate = useTranslate();
  let filesListCss = FilesListCss();

  const update = (event: any, fileId: string) => {
    if (event.target.checked) {
      updateListChecked([...listChecked, fileId]);
    } else {
      updateListChecked(listChecked.filter((element) => element !== fileId));
    }
  };

  const fetchFilesDetails = useCallback(() => {
    setLoading(true);
    API.get("api", `/tracking/${configurationName}${configurationCode}`, {
      queryStringParameters: {
        filter: JSON.stringify(filterValues),
        pagination: JSON.stringify({ page: page, perPage: 500 }),
        sort: JSON.stringify({ field: "fileEndDateTimestamp", order: "DESC" }),
      },
    })
      .then((data) => {
        if (!data.Items.length) {
          setNextPage(false);
        }
        setRows((rows) => [...rows, ...data.Items]);
        setPage((page) => page + 1);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filterValues, page, configurationCode, configurationName]);

  if (error) {
    return <p>ERROR</p>;
  }

  return loading ? (
    <CircularProgress />
  ) : (
    <div className={filesListCss.div}>
      <InitFiles record={record} data={rows}></InitFiles>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">{translate("resources.tracking.files.action")}</TableCell>
            <TableCell align="left">{translate("resources.tracking.files.fileName")}</TableCell>
            <TableCell align="left">
              {translate("resources.tracking.files.executionStartDate")}
            </TableCell>
            <TableCell align="left">
              {translate("resources.tracking.files.executionEndDate")}
            </TableCell>
            <TableCell align="left">{translate("resources.tracking.files.status")}</TableCell>
            <TableCell align="left">
              {translate("resources.tracking.files.targetConnexionName")}
            </TableCell>
            <TableCell align="left">{translate("resources.tracking.files.errorDetails")}</TableCell>
            <TableCell align="left">{translate("resources.tracking.files.accessLogs")}</TableCell>
            <TableCell align="left">{translate("resources.tracking.files.downloadFile")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any) => {
            const fileId = row.id;
            return (
              <TableRow
                key={fileId + Math.random().toString()}
                className={calculateColorByStatus(row.progression, filesListCss)}
              >
                <TableCell className={filesListCss.textColor}>
                  <Checkbox
                    disabled={disabledSelectionFilesStatus.includes(row.progression)}
                    name={row.fileName}
                    value={record.id}
                    id={fileId}
                    onChange={(event) => {
                      update(event, fileId);
                    }}
                    checked={listChecked.some((element) => element === fileId)}
                  />
                </TableCell>
                <TableCell
                  className={filesListCss.textColor}
                  title={row.executionCorrelationId}
                  align="left"
                >
                  {row.fileName}
                </TableCell>
                <TableCell className={filesListCss.textColor} align="left">
                  {row.fileStartDate ? row.fileStartDate : "-"}
                </TableCell>
                <TableCell className={filesListCss.textColor} align="left">
                  {row.fileEndDate ? row.fileEndDate : "-"}
                </TableCell>
                <TableCell className={filesListCss.textColor} align="left">
                  {row.progression}
                </TableCell>
                <TableCell
                  className={filesListCss.textColor}
                  title={row.targetName ? row.targetName : "-"}
                  align="left"
                >
                  {row.targetConnexionName ? row.targetConnexionName : "-"}
                </TableCell>
                <TableCell className={filesListCss.textColor} align="left">
                  {row.errorDetail ? row.executionEndDate : "-"}
                </TableCell>
                <TableCell align="center">
                  {authorizeAction(permissions, "tracking", "log") ? (
                    <OpenLogsButton record={row} />
                  ) : (
                    <></>
                  )}
                </TableCell>
                <TableCell align="center">
                  {authorizeDownload(permissions, [row.sourceApplication, row.targetApplication]) ? (
                    <DownloadButton
                      record={{
                        ...row,
                        configurationId: `${configurationName}${configurationCode}`,
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <NextPage hasNextPage={hasNextPage} next={fetchFilesDetails} isLoading={isLoading} />
    </div>
  );
};

function NextPage(props: any) {
  let { next, hasNextPage, isLoading } = props;
  const t = useTranslate();
  const observerRef = useRef(null);
  const getMore = useCallback(
    (entries) => {
      const [target] = entries;
      if (target.isIntersecting && hasNextPage) {
        next();
      }
    },
    [next, hasNextPage]
  );

  useEffect(() => {
    const watchElement = observerRef.current;
    if (!watchElement) return;
    const observer = new IntersectionObserver(getMore);
    observer.observe(watchElement);
    return () => observer.unobserve(watchElement);
  }, [getMore]);

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "5px" }}>
      {isLoading ? <CircularProgress size={"2rem"} /> : ""}
      {!hasNextPage ? (
        <p>{t("resources.tracking.files.allFilesRetrieved")}</p>
      ) : (
        <div style={{ width: "10px", height: "10px" }} ref={observerRef}></div>
      )}
    </div>
  );
}

export default FileList;
