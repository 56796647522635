import { DateField, ReferenceField, TextField } from "react-admin";
import ListBase from "../BaseModel/List";

const ListForm = (props: any) => (
  <ListBase {...props}>
    <ReferenceField source="type" reference="integrationType" link="show">
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField source="connection" reference="connection" link="show">
      <TextField source="name" />
    </ReferenceField>
    <TextField source="parameters.path" />
    <TextField source="parameters.file_name" />
    <TextField source="parameters.content_pattern" />
    <ReferenceField source="created_by" reference="user" link="show">
      <TextField source="name" />
    </ReferenceField>
    <DateField source="created_at" showTime={true} />
    <ReferenceField source="updated_by" reference="user" link="show">
      <TextField source="name" />
    </ReferenceField>
    <DateField source="updated_at" showTime={true} />
  </ListBase>
);

export default ListForm;
