import { Datagrid, List, TextField, Pagination, Button } from "react-admin";
import SimulerAction from "@/Components/CustomMigrationAction/SimulerAction";
import { authorizeAction } from "@/Settings/roles";

const PostPagination = () => <Pagination rowsPerPageOptions={[15, 25, 50, 100]} />;

const ListForm = (props: any) => {
  function refresh() {
    window.location.reload();
  }
  return (
    <div>
      <Button label={"refresh"} onClick={refresh} />
      <List {...props} syncWithLocation={false} perPage={15} pagination={<PostPagination />}>
        <Datagrid {...props}>
          <TextField source="serverID" label={"server id"} />
          <TextField source="applicationId" label={"Application ID"} />
          <TextField source="serverInformations.ftpMode" label={"fto mode"} />
          <TextField source="serverInformations.ftpPort" label={"ftp port"} />
          <TextField source="activated" label={"activated"} />
          {authorizeAction(props.options.roles, props.resource, "simulate") && <SimulerAction />}
        </Datagrid>
      </List>
    </div>
  );
};
export default ListForm;
