import Button from "@material-ui/core/Button";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Toolbar from "@material-ui/core/Toolbar";
import { connect } from "react-redux";

const CustomPagination = ({ page, setPage, nextToken , setNextToken}: any) => {
  return (
    (page > 1 || nextToken) && (
      <Toolbar>
        {page > 1 && (
          <Button color="primary" key="prev" onClick={() => {
              setPage(page - 1);
              setNextToken("prev");
          }}>
            <ChevronLeft />
            Prev
          </Button>
        )}
        {nextToken && (
          <Button color="primary" key="next" onClick={() => setPage(page + 1)}>
            Next
            <ChevronRight />
          </Button>
        )}
      </Toolbar>
    )
  );
};

const mapStateToProps = (state: any) => ({ nextToken: state.nextToken });
export default connect(mapStateToProps)(CustomPagination);
