import { downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";
import { dropDuplicates, removeEmptyOrNullString } from "@/Tools/helpers";
import { getConnections, getUsers, cleanText } from "@/Tools/exporterTools";

const IntegrationExporter = async (records: any) => {
  const connectionIds = dropDuplicates(
    removeEmptyOrNullString([...records.map((record: any) => record.parameters.connection)].flat())
  );
  const userIds = dropDuplicates(
    removeEmptyOrNullString([
      ...records.map((record: any) => record.created_by),
      ...records.map((record: any) => record.updated_by),
    ])
  );
  const connections = await getConnections(connectionIds);
  const users = await getUsers(userIds);
  const data = records.map((record: any) => {
    return {
      ...record,
      description: cleanText(record.description),
      parameters: {
        ...record.parameters,
        connection: connections[record.connection],
      },
      created_by: users[record.created_by] ?? record.created_by,
      updated_by: users[record.updated_by] ?? record.updated_by,
    };
  });

  return jsonExport(data, { rowDelimiter: "," }, (error: any, csv: any) => {
    if (error) console.log("Error CSV Export : ", error);
    downloadCSV(csv, `integrationsConfig_export_${Date.now()}`);
  });
};

export default IntegrationExporter;
