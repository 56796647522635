import {
  BooleanInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useInput,
  useTranslate,
} from "react-admin";
import { useCallback, useEffect, useState } from "react";
import { ConditionalInput } from "@/Components/ConditionalForm";
import API from "@aws-amplify/api-rest";
import { useForm } from "react-final-form";
type IntegrationProps = {
  source: string;
  label: string;
};

const IntegrationTarget = ({ source, label }: IntegrationProps) => {
  const translate = useTranslate();

  const form = useForm();
  const { id } = useInput({ source });
  const [useIntegration, setIntegration] = useState(false);
  const [integrationTemplateConnection, setIntegrationTemplateConnection] = useState("");
  const [integrationTemplateId, setIntegrationTemplateId] = useState("");
  const [integrationType, setIntegrationType] = useState("");

  const prefill = useCallback(
    (template: any) => {
      form.change(`${id}.type`, template.type);
      form.change(`${id}.parameters.path`, template.parameters?.path);
      form.change(`${id}.parameters.connection`, template.parameters?.connection);
      form.change(`${id}.parameters.file_name`, template.parameters?.file_name);
      form.change(`${id}.parameters.content_pattern`, template.parameters?.content_pattern);
      form.change(
        `${id}.parameters.use_target_connection`,
        template.parameters?.use_target_connection ?? false
      );
    },
    [form, id]
  );

  useEffect(() => {
    let currentIndex = parseInt(id[8]);
    let values = form.getState().values;
    let integration = values.targets[currentIndex].integration;

    if (integration.use_integration) {
      getIntegrationDetails(integration.template);
      prefill(integration);
    }

    setIntegration(integration.use_integration);
  }, [id, form, prefill]);

  //Reset integration
  const resetIntegrationTemplate = () => {
    form.change(`${id}.template`, "");
    form.change(`${id}.type`, "");
  };

  const clearIntegrationValues = () => {
    form.change(`${id}.parameters.path`, "");
    form.change(`${id}.parameters.connection`, "");
    form.change(`${id}.parameters.file_name`, "");
    form.change(`${id}.parameters.content_pattern`, "");
    form.change(`${id}.parameters.use_target_connection`, false);
    setIntegrationTemplateConnection("");
  };

  const toggleIntegration = (event: boolean) => {
    setIntegration(event);
    resetIntegrationTemplate();
    clearIntegrationValues();
  };

  const toggleConnection = (event: boolean) => {
    if (event) {
      form.change(`${id}.parameters.connection`, "");
      return;
    }
    form.change(`${id}.parameters.connection`, integrationTemplateConnection);
  };

  const getIntegrationTemplate = async (event: any) => {
    clearIntegrationValues();
    if (event === "") return;

    let integrationId: string = event.target.value;
    let json = await getIntegrationDetails(integrationId);
    prefill(json);
  };

  const getIntegrationDetails = (integrationId: string) => {
    return API.get("api", `/integrationConfiguration/${integrationId}`, {}).then((json) => {
      setIntegrationTemplateId(json.id);
      setIntegrationType(json.type);
      setIntegrationTemplateConnection(json.parameters.connection);
      return json;
    });
  };

  const sortable = { field: "name", order: "ASC" };

  return (
    <>
      <BooleanInput
        label={label}
        source={`${id}.use_integration`}
        defaultValue={useIntegration}
        onChange={toggleIntegration}
      />
      <ConditionalInput
        validate={(values: any) => {
          let targetIndex = id[8];
          if (!values || !values.targets) return false;
          if (values.targets.length <= parseInt(targetIndex)) return false;
          return values.targets[parseInt(targetIndex)].integration.use_integration === true;
        }}
      >
        <ReferenceInput
          source={`${id}.template`}
          reference="integrationConfiguration"
          label={translate("resources.configuration.fields.target.integrationTemplate")}
          validate={required()}
          onChange={getIntegrationTemplate}
          sort={sortable}
        >
          <SelectInput
            defaultValue={integrationTemplateId}
            optionText="name"
            resettable={true}
            style={integrationInputStyle}
          />
        </ReferenceInput>
        <br></br>
        <div style={integrationStyle}>
          <TextInput
            source={`${id}.type`}
            label={translate("resources.configuration.fields.target.integrationType")}
            defaultValue={integrationType}
            disabled
          />
          <TextInput
            source={`${id}.parameters.path`}
            label={translate("resources.configuration.fields.target.integrationPath")}
            validate={required()}
            style={integrationInputStyle}
          />
          <TextInput
            source={`${id}.parameters.file_name`}
            label={translate("resources.configuration.fields.target.integrationFilename")}
            validate={required()}
            style={integrationInputStyle}
          />
          <TextInput
            source={`${id}.parameters.content_pattern`}
            label={translate("resources.configuration.fields.target.integrationContentPattern")}
            validate={required()}
            style={integrationInputStyle}
          />

          <BooleanInput
            defaultValue={false}
            source={`${id}.parameters.use_target_connection`}
            onChange={toggleConnection}
            label={translate(
              "resources.configuration.fields.target.integrationUseCurrentConnection"
            )}
          />

          <ReferenceInput
            source={`${id}.parameters.connection`}
            reference="connection"
            label={translate("resources.configuration.fields.target.integrationConnection")}
            sort={sortable}
          >
            <SelectInput optionText="name" style={integrationInputStyle} disabled />
          </ReferenceInput>
        </div>
      </ConditionalInput>
    </>
  );
};

const integrationStyle = {
  width: "50%",
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "flex-start",
  justifyContent: "center",
  borderLeft: "1px solid black",
  borderBottom: "1px solid black",
  borderBottomLeftRadius: "15px",
  paddingLeft: "1rem",
};
const integrationInputStyle = {
  minWidth: "43%",
};
export default IntegrationTarget;
