import { Error, Loading, usePermissions, useQuery } from "react-admin";
import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import Alert from "@mui/material/Alert";
import { useState } from "react";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { GridRowId } from "@mui/x-data-grid/models/gridRows";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import API from "@aws-amplify/api-rest";
import { CircularProgress } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { authorizeAction } from "@/Settings/roles";
import { Redirect } from "react-router-dom";

const Simuler = (props: any) => {
  const { loaded, permissions } = usePermissions();

  let [toShow, setToShow] = useState(false);
  // states
  let [selectedData, setSelectedData] = useState<any[]>([]);
  let [selectedIdFlow, setSelectedIdFlow] = useState<GridRowId[]>([]);
  const [open, setOpen] = useState(false);
  const [alerte, setAlerte] = useState(false);
  const [action, setAction] = useState("");
  const [progressBar, setProgressBar] = useState(false);
  const [value, setValue] = useState("");
  const [activated, setActivated] = useState(false);

  const { data, loading, error } = useQuery({
    type: "getOne",
    resource: "migration",
    payload: { id: props.match?.params?.id },
  });
  if (loading) return <Loading />;
  if (error) return <Error {...error} />;
  if (!data) return null;
  const columnsConnection: GridColDef[] = [
    { field: "name", headerName: "name", width: 200 },
    { field: "enabled", headerName: "enabled", width: 200 },
    { field: "created_by", headerName: "created_by", width: 200 },
    { field: "type", headerName: "type", width: 200 },
    { field: "description", headerName: "description", width: 400 },
  ];
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 130 },
    { field: "name", headerName: "name", width: 130 },
    {
      field: "source.application",
      headerName: "application",
      width: 130,
      valueGetter: (params) => {
        return params.getValue(params.id, "source").application;
      },
    },
    {
      field: "source.path",
      headerName: "path",
      width: 180,
      valueGetter: (params) => {
        return params.getValue(params.id, "source").path;
      },
    },
    { field: "criticity", headerName: "criticity", width: 130 },
    { field: "direct", headerName: "direct", width: 130 },
    { field: "enabled", headerName: "enabled", width: 130 },
    {
      field: "source.enabled",
      headerName: "source enabled",
      width: 130,
      valueGetter: (params) => {
        return params.getValue(params.id, "source").enabled;
      },
    },
    {
      field: "source.regex",
      headerName: "source regex",
      width: 130,
      valueGetter: (params) => {
        return params.getValue(params.id, "source").regex;
      },
    },
    {
      field: "source.transfert_type",
      headerName: "transfert_type",
      width: 130,
      valueGetter: (params) => {
        return params.getValue(params.id, "source").transfert_type;
      },
    },
    {
      field: "source.transfert_mode",
      headerName: "transfert_mode",
      width: 130,
      valueGetter: (params) => {
        return params.getValue(params.id, "source").transfert_mode;
      },
    },
    {
      field: "source.nb_files_per_transfert",
      headerName: "nb_files_per_transfert",
      width: 130,
      valueGetter: (params) => {
        return params.getValue(params.id, "source").nb_files_per_transfert;
      },
    },
    {
      field: "target.description",
      headerName: "target description",
      width: 130,
      valueGetter: (params) => {
        return params.getValue(params.id, "targets")[0].description;
      },
    },
    {
      field: "target.enabled",
      headerName: "target enabled",
      width: 130,
      valueGetter: (params) => {
        return params.getValue(params.id, "targets")[0].enabled;
      },
    },
    {
      field: "target.application",
      headerName: "target application",
      width: 130,
      valueGetter: (params) => {
        return params.getValue(params.id, "targets")[0].application;
      },
    },
    {
      field: "target.path",
      headerName: "target path",
      width: 180,
      valueGetter: (params) => {
        return params.getValue(params.id, "targets")[0].path;
      },
    },
    {
      field: "target.pattern_file_name",
      headerName: "target pattern_file_name",
      width: 130,
      valueGetter: (params) => {
        return params.getValue(params.id, "targets")[0].pattern_file_name;
      },
    },
    {
      field: "target.error_if_file_exists",
      headerName: "target error_if_file_exists::",
      width: 130,
      valueGetter: (params) => {
        return params.getValue(params.id, "targets")[0].error_if_file_exists;
      },
    },
    {
      field: "target.new_name",
      headerName: "target new_name",
      width: 130,
      valueGetter: (params) => {
        return params.getValue(params.id, "targets")[0].new_name;
      },
    },
    {
      field: "target.nb_files_per_transfert",
      headerName: "target nb_files_per_transfert",
      width: 130,
      valueGetter: (params) => {
        return params.getValue(params.id, "targets")[0].nb_files_per_transfert;
      },
    },
  ];

  function getConnections(ids: any[]): any[] {
    return ids.map((id) => {
      const result = data.Items.find((element: any) => element.id === id);
      return result.connections;
    });
  }

  function migrer() {
    setProgressBar(true);
    const url = `/migration`;
    API.post("api", url, {
      body: selectedIdFlow,
    }).then((json) => {
      handleCloseProgressBar();
      setAlerte(true);
      setActivated(false);
      return {
        data: json.Items,
      };
    });
    setOpen(false);
    setAction("Migration");
  }

  function activer() {
    setProgressBar(true);
    const url = `/migration`;
    API.put("api", url, {
      body: selectedIdFlow,
    }).then((json) => {
      handleCloseProgressBar();
      setAlerte(true);
      setActivated(true);
      setValue(json);
      return {
        data: json.Items,
      };
    });
    setOpen(false);
    setAction("Activation");
  }

  function onSelection(newSelectionModel: GridSelectionModel) {
    if (newSelectionModel && newSelectionModel.length > 0) {
      setSelectedIdFlow(newSelectionModel);
      setToShow(true);
      const connections = getConnections(newSelectionModel);
      setSelectedData(connections.flat());
    } else {
      setToShow(false);
      setSelectedData([]);
      setSelectedIdFlow([]);
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAlerte = () => {
    setAlerte(false);
  };
  const handleCloseProgressBar = () => {
    setProgressBar(false);
  };

  return loaded ? (
    authorizeAction(permissions, props.resource, "simulate") ? (
      <div>
        <div style={{ height: 500, width: "100%" }}>
          <DataGrid
            rows={data.Items}
            columns={columns}
            pageSize={15}
            rowsPerPageOptions={[15]}
            checkboxSelection
            onSelectionModelChange={(newSelectionModel) => onSelection(newSelectionModel)}
          />
        </div>
        <Alert severity="warning">
          Attention les connexions ne seront pas dupliqué en base en données (1 entrée par host)
        </Alert>
        {toShow && (
          <div style={{ height: 500, width: 1400 }}>
            <DataGrid
              rows={selectedData}
              columns={columnsConnection}
              pageSize={15}
              rowsPerPageOptions={[15]}
              checkboxSelection
            />
          </div>
        )}
        <Divider /> <br />
        {toShow && (
          <Stack spacing={2} direction="row" alignContent={"right"}>
            <Button variant="contained" onClick={handleClickOpen}>
              Migrer
            </Button>
            <Button variant="contained" onClick={activer}>
              Activer
            </Button>
          </Stack>
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Vous étes entrain de faire une migration"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Etes-vous sur de vouloir migrer les flux {selectedIdFlow.join(",")} vers MFT?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={migrer}>Oui</Button>
            <Button onClick={handleClose}>Non</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={alerte}
          onClose={handleCloseAlerte}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {action} effectuée avec succès
              {activated && (
                <Stack spacing={2} direction="row" alignContent={"right"}>
                  <CopyToClipboard text={value}>
                    <Button>Copier la réquéte de désactivation</Button>
                  </CopyToClipboard>
                </Stack>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAlerte}>Ok</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={progressBar}
          aria-labelledby="alert-dialog-title"
          onClose={handleCloseProgressBar}
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Traitement en cours
              <CircularProgress />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    ) : (
      <Redirect to={"/"} />
    )
  ) : (
    <p>Loading</p>
  );
};
export default Simuler;
