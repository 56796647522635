import { ReferenceInput, required, SelectInput, TextInput } from "react-admin";
import CreateBase from "../BaseModel/Create";

const sort = { field: "name", order: "ASC" };
const CreateForm = (props: any) => (
  <CreateBase {...props}>
    <ReferenceInput source="type" reference="integrationType" validate={[required()]}>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="parameters.connection"
      reference="connection"
      validate={[required()]}
      sort={sort}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput source="parameters.path" />
    <TextInput source="parameters.file_name" />
    <TextInput source="parameters.content_pattern" />
  </CreateBase>
);

export default CreateForm;
