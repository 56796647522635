import { useSelector } from "react-redux";
import { AppState } from "@/types";
import RichTextInput from "ra-input-rich-text";
import {
  TabbedForm,
  FormTab,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  Create,
  BooleanInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  useTranslate,
  useQuery,
  Loading,
  Error,
  maxLength,
  FormDataConsumer,
  usePermissions,
} from "react-admin";
import { scheduleValidation } from "..";
import TargetDelay from "./TargetDelay";
import IntegrationTarget from "./IntegrationTarget";
import { authorizeAction } from "@/Settings/roles";
import { Redirect } from "react-router-dom";
import { useRedirectWithParams } from "@/Tools/hooks";
import { clearStyleFromHtml, toUpperCase, validateFilename } from "@/Tools/helpers";
import {
  DEFAULT_POLLING_TYPE,
  DEFAULT_S3_DEPOSIT_CONNECTION,
  DEFAULT_TRANSFERT_MODE,
  DEFAULT_TRANSFERT_TYPE,
} from "./constants";
import { useEffect, useRef, useState } from "react";
import API from "@aws-amplify/api-rest";
import { S3DepositSource } from "@/Components/Models/Configuration/S3DepositSource";
import { StandardSource } from "@/Components/Models/Configuration/StandardSource";

const EditForm = (props: any) => {
  const s3DepositConnection = useRef("");
  const [useS3Deposit, setS3Deposit] = useState(false);
  const redirect = useRedirectWithParams("configuration", [["from", "copy"]]);
  const { loaded, permissions } = usePermissions();
  const translate = useTranslate();
  const theme = useSelector((state: AppState) => (state.theme === "dark" ? "darkTheme" : ""));

  const { data, loading, error } = useQuery({
    type: "getOne",
    resource: `configuration`,
    payload: { id: props.match?.params?.id },
  });

  useEffect(() => {
    API.get("api", `/connection`, {
      queryStringParameters: {
        filter: JSON.stringify({ name: DEFAULT_S3_DEPOSIT_CONNECTION }),
      },
    }).then((json) => {
      let s3Connection = json.Items[0];
      s3DepositConnection.current = s3Connection.id;
    });
  }, []);

  useEffect(() => {
    if (data) setS3Deposit(data?.source.use_s3_deposit);
  }, [data]);

  if (loading) return <Loading />;
  if (error) return <Error {...error} />;
  if (!data) return null;
  else {
    // init value
    data.code = "";
    data.name = "";
  }

  const object2 = {
    ...props,
    permissions: undefined,
    basePath: "/configuration",
    hasCreate: true,
    hasEdit: true,
    hasList: true,
    hasShow: true,
    id: props.match?.params?.id,
    resource: "configuration",
  };

  const cleanFields = (data: any) => {
    const { id, created_at, created_by, updated_at, updated_by, ...rest } = data;
    const useSourceTransformations = rest.source.transformations ? true : false;
    const useSourceMoveAfter = rest.source.transfert_type === "MOVE_AFTER" ? true : false;
    if (!useSourceTransformations) delete rest["source"]["transformations"];
    if (!useSourceMoveAfter) delete rest["source"]["transfert_path"];

    if (!rest.source.use_s3_deposit) {
      return {
        ...rest,
        source: {
          ...rest.source,
          use_s3_deposit: false,
        },
        targets: rest.targets.map((target: any) => {
          const useTargetTransformations = target.transformations ? true : false;
          if (!useTargetTransformations) delete target["transformations"];
          return {
            ...target,
          };
        }),
      };
    }

    const emptyFilters = {
      unitType: "",
      unit: 0,
    };
    return {
      ...rest,
      source: {
        ...rest.source,
        use_s3_deposit: rest.source.use_s3_deposit,
        connection: s3DepositConnection.current,
        path: "",
        regex: "",
        transfert_type: DEFAULT_TRANSFERT_TYPE,
        transfert_mode: DEFAULT_TRANSFERT_MODE,
        nb_files_per_transfert: "-1",
        polling: DEFAULT_POLLING_TYPE,
        filters_enabled: false,
        filters: emptyFilters,
      },
      targets: rest.targets.map((target: any) => {
        const useTargetTransformations = target.transformations ? true : false;
        if (!useTargetTransformations) delete target["transformations"];
        return {
          ...target,
        };
      }),
    };
  };

  const handleChange = (event: boolean) => {
    setS3Deposit(event);
  };

  return loaded ? (
    authorizeAction(permissions, "configuration", "copy") ? (
      <Create
        {...object2}
        record={data}
        mutationMode="pessimistic"
        onSuccess={redirect}
        transform={cleanFields}
      >
        <TabbedForm
          warnWhenUnsavedChanges
          syncWithLocation={false}
          record={data}
          sanitizeEmptyValues={false}
        >
          <FormTab label="Summary">
            <TextInput source="name" validate={[required(), maxLength(4)]} parse={toUpperCase} />
            <TextInput source="code" validate={[required(), maxLength(6)]} parse={toUpperCase} />
            <RichTextInput
              source="description"
              validate={[required()]}
              parse={clearStyleFromHtml}
            />
            <ReferenceInput source="criticity" reference="criticityType" validate={[required()]}>
              <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="requestor" validate={[maxLength(100)]} />
            <TextInput source="demand_number" validate={[maxLength(100)]} />
            <BooleanInput source="enabled" defaultValue={true} />
            <BooleanInput source="direct" defaultValue={false} />
          </FormTab>

          <FormTab label="Source" value={"1"} record={props.record} path={"1"}>
            <BooleanInput
              source="source.use_s3_deposit"
              label={"Use S3 Deposit"}
              onChange={handleChange}
            />
            {useS3Deposit ? (
              <S3DepositSource s3DepositConnection={s3DepositConnection.current} />
            ) : (
              <StandardSource />
            )}
          </FormTab>

          <FormTab label="Target">
            <ArrayInput source="targets">
              <SimpleFormIterator className={`${theme} multiTargetConfig`}>
                <RichTextInput
                  source="description"
                  validate={[required()]}
                  label={translate("resources.configuration.fields.target.description")}
                  parse={clearStyleFromHtml}
                />
                <ReferenceInput
                  source="connection"
                  reference="connection"
                  validate={[required()]}
                  label={translate("resources.configuration.fields.target.connection")}
                  sort={{ field: "name", order: "ASC" }}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
                <BooleanInput
                  source="enabled"
                  defaultValue={true}
                  label={translate("resources.configuration.fields.target.enabled")}
                />
                <BooleanInput
                  source="error_if_file_exists"
                  defaultValue={false}
                  label={translate("resources.configuration.fields.target.error_if_file_exists")}
                />
                <TextInput
                  source="path"
                  validate={[required()]}
                  label={translate("resources.configuration.fields.target.path")}
                />
                <ReferenceInput
                  source="application"
                  reference="application"
                  validate={[required()]}
                  label={translate("resources.configuration.fields.target.application")}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput
                  label={translate("resources.configuration.fields.target.wisp_group")}
                  source="wisp_group"
                />
                <TextInput
                  source="job"
                  label={translate("resources.configuration.fields.target.job")}
                />
                <TextInput
                  source="tinformation"
                  label={translate("resources.configuration.fields.target.information")}
                />
                <ReferenceInput
                  source="pattern_file_name"
                  reference="fileNamePattern"
                  validate={[required()]}
                  label={translate("resources.configuration.fields.target.pattern_file_name")}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
                <FormDataConsumer>
                  {({ formData, scopedFormData, getSource, ...rest }) =>
                    scopedFormData && scopedFormData.pattern_file_name === "TRIM" ? (
                      <TextInput
                        source={getSource?.("trim_character") || "targets[0].trim_character"}
                        validate={[required()]}
                        label={translate("resources.fileNamePattern.fields.trim_character")}
                      />
                    ) : null
                  }
                </FormDataConsumer>
                <FormDataConsumer>
                  {({ formData, scopedFormData, getSource, ...rest }) =>
                    scopedFormData &&
                    (scopedFormData.pattern_file_name === "RENAME" ||
                      scopedFormData.pattern_file_name === "RENAME_CHRONO" ||
                      scopedFormData.pattern_file_name === "RENAME_NOW") ? (
                      <TextInput
                        source={getSource?.("new_name") || "targets[0].new_name"}
                        validate={[required(), validateFilename]}
                        label={translate("resources.fileNamePattern.fields.new_name")}
                      />
                    ) : null
                  }
                </FormDataConsumer>
                <NumberInput
                  source="nb_files_per_transfert"
                  defaultValue={-1}
                  label={translate("resources.configuration.fields.target.nb_files_per_transfert")}
                />
                <TargetDelay
                  source="delay"
                  label={translate("resources.configuration.fields.target.delay")}
                />
                <FormDataConsumer>
                  {({ formData, scopedFormData, getSource, ...rest }) =>
                    formData && formData.direct === false ? (
                      <TextInput
                        source={
                          getSource?.("schedule_expression") || "targets[0].schedule_expression"
                        }
                        validate={[required(), scheduleValidation]}
                        label={translate(
                          "resources.configuration.fields.source.schedule_expression"
                        )}
                      />
                    ) : null
                  }
                </FormDataConsumer>
                <IntegrationTarget
                  source="integration"
                  label={translate("resources.configuration.fields.target.integration")}
                />
                <ArrayInput
                  source="transformations"
                  label={translate("resources.configuration.fields.target.transformations")}
                >
                  <SimpleFormIterator>
                    <ReferenceInput
                      source=""
                      reference="transformationType"
                      validate={[required()]}
                      label="resources.configuration.fields.transformation"
                    >
                      <SelectInput optionText="name" />
                    </ReferenceInput>
                  </SimpleFormIterator>
                </ArrayInput>
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>
        </TabbedForm>
      </Create>
    ) : (
      <Redirect to={"/"} />
    )
  ) : (
    <p>Loading</p>
  );
};

export default EditForm;
