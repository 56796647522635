import { accessMenu } from "@/Settings/roles";
import {
  BooleanField,
  ChipField,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  RichTextField,
  Show,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  ArrayField,
  TextField,
  useTranslate,
  Datagrid,
  usePermissions,
  Loading,
} from "react-admin";
import { ShowActionsTopToolbar } from "..";

const ShowForm = (props: any) => {
  const { permissions, loading } = usePermissions();
  const translate = useTranslate();

  return loading ? (
    <Loading />
  ) : (
    <Show actions={<ShowActionsTopToolbar roles={props.options.roles} />} {...props}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <TextField source="name" />
          <TextField source="code" />
          <RichTextField source="description" />
          <TextField source="requestor" />
          <TextField source="demand_number" />
          <ReferenceField
            source="criticity"
            reference="criticityType"
            link={accessMenu(permissions, "criticityType") ? "show" : false}
          >
            <TextField source="name" />
          </ReferenceField>
          <BooleanField source="enabled" />
          <BooleanField source="direct" />
        </Tab>
        <Tab label="Source">
          <BooleanField source="source.use_s3_deposit" />
          <RichTextField source="source.description" />
          <ReferenceField
            source="source.connection"
            reference="connection"
            link={accessMenu(permissions, "connection") ? "show" : false}
          >
            <TextField source="name" />
          </ReferenceField>
          <BooleanField source="source.enabled" />
          <TextField source="source.path" />
          <ReferenceField
            source="source.application"
            reference="application"
            link={accessMenu(permissions, "application") ? "show" : false}
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="source.wisp_group" />
          <TextField source="source.job" />
          <TextField source="source.information" />
          <TextField source="source.regex" defaultValue={"*"} />
          <ReferenceField
            source="source.transfert_type"
            reference="transfertType"
            link={accessMenu(permissions, "transfertType") ? "show" : false}
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="source.transfert_path" />
          <ReferenceField
            source="source.transfert_mode"
            reference="transfertMode"
            link={accessMenu(permissions, "transfertMode") ? "show" : false}
          >
            <TextField source="name" />
          </ReferenceField>
          <NumberField source="source.nb_files_per_transfert" defaultValue={-1} />
          <ReferenceField
            source="source.polling"
            reference="pollingType"
            link={accessMenu(permissions, "pollingType") ? "show" : false}
          >
            <TextField source="name" />
          </ReferenceField>
          <BooleanField
            source="source.filters_enabled"
            label={translate("resources.configuration.fields.source_filters")}
          />
          <TextField
            source="source.filters.unitType"
            label={translate("resources.configuration.fields.source_filters_unitType")}
          />
          <TextField
            source="source.filters.unit"
            label={translate("resources.configuration.fields.source_filters_unit")}
          />
          <TextField source="source.schedule_expression" />
          <ReferenceArrayField source="source.transformations" reference="transformationType">
            <SingleFieldList
              linkType={accessMenu(permissions, "transformationType") ? "show" : false}
            >
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        </Tab>
        <Tab label="Target">
          <ArrayField source="targets">
            <Datagrid>
              <RichTextField
                source="description"
                label={translate("resources.configuration.fields.target.description")}
              />
              <ReferenceField
                source="connection"
                reference="connection"
                link={accessMenu(permissions, "connection") ? "show" : false}
                label={translate("resources.configuration.fields.target.connection")}
              >
                <TextField source="name" />
              </ReferenceField>
              <BooleanField
                source="enabled"
                label={translate("resources.configuration.fields.target.enabled")}
              />
              <BooleanField source="error_if_file_exists" />
              <TextField
                source="path"
                label={translate("resources.configuration.fields.target.path")}
              />
              <ReferenceField
                source="application"
                reference="application"
                link={accessMenu(permissions, "application") ? "show" : false}
                label={translate("resources.configuration.fields.target.application")}
              >
                <TextField source="name" />
              </ReferenceField>
              <TextField source="wisp_group" />
              <TextField
                source="job"
                label={translate("resources.configuration.fields.target.job")}
              />
              <TextField
                source="information"
                label={translate("resources.configuration.fields.target.information")}
              />
              <ReferenceField
                source="transfert_type"
                reference="transfertType"
                link={accessMenu(permissions, "transfertType") ? "show" : false}
                label={translate("resources.configuration.fields.target.transfer_type")}
              >
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                source="pattern_file_name"
                reference="fileNamePattern"
                link={accessMenu(permissions, "fileNamePattern") ? "show" : false}
                label={translate("resources.fileNamePattern.fields.new_name")}
              >
                <TextField source="name" />
              </ReferenceField>
              <NumberField
                source="nb_files_per_transfert"
                defaultValue={-1}
                label={translate("resources.configuration.fields.target.nb_files_per_transfert")}
              />
              <NumberField
                source="delay.delay_at_put"
                defaultValue={0}
                label={translate("resources.configuration.fields.target.delay")}
              />
              <TextField
                source="schedule_expression"
                label={translate("resources.configuration.fields.source.schedule_expression")}
              />
              <BooleanField
                source="integration.use_integration"
                label={translate("resources.configuration.fields.target.integration")}
              />
              <TextField
                source="integration.template"
                label={translate("resources.configuration.fields.target.integrationTemplate")}
              />
              <TextField
                source="integration.parameters.path"
                label={translate("resources.configuration.fields.target.integrationPath")}
              />
              <TextField
                source="integration.parameters.file_name"
                label={translate("resources.configuration.fields.target.integrationFilename")}
              />
              <TextField
                source="integration.parameters.content_pattern"
                label={translate("resources.configuration.fields.target.integrationContentPattern")}
              />
              <ReferenceField
                source="integration.parameters.connection"
                reference="connection"
                link={accessMenu(permissions, "connection") ? "show" : false}
                label={translate("resources.configuration.fields.target.integrationConnection")}
              >
                <TextField source="name" />
              </ReferenceField>
              <ReferenceArrayField
                source="transformations"
                reference="transformationType"
                label={translate("resources.configuration.fields.target.transformations")}
              >
                <SingleFieldList
                  linkType={accessMenu(permissions, "transformations") ? "show" : false}
                >
                  <ChipField source="name" />
                </SingleFieldList>
              </ReferenceArrayField>
              <BooleanField
                source="auto_disabled"
                label={translate("resources.configuration.fields.target.autoDisabled")}
              />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ShowForm;
