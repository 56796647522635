import { Model } from "@/Components/Models";

export type RoleObject = {
  id: string;
  name: string;
  description: string;
};

export const SUPER_ADMIN = "SuperAdmin";
export const ADMIN_ROLE = "Admin";
export const READONLY_ROLE = "ReadOnly";
export const OPERATOR_TRACKING_ROLE = "OperatorTracking";

type ActionsAccess = {
  [key: string]: string[];
};
type ResourceAccess = {
  resources: {
    [key: string]: string[];
  };
  menu: string[];
};
type AccessList = {
  [key: string]: ResourceAccess;
};

const AuthorizedActions: ActionsAccess = {
  admin: ["All"],
  read: ["list", "show", "overview", "export", "refresh", "download"],
  operator: [
    "list",
    "show",
    "overview",
    "export",
    "refresh",
    "retry",
    "abandon",
    "log",
    "download",
    "explore"
  ],
};

const settings = {
  authType: AuthorizedActions["read"],
  settings: AuthorizedActions["read"],
  userRolesType: AuthorizedActions["admin"],
  connectionType: AuthorizedActions["read"],
  transfertType: AuthorizedActions["read"],
  transfertMode: AuthorizedActions["read"],
  criticityType: AuthorizedActions["read"],
  pollingType: AuthorizedActions["read"],
  fileNamePattern: AuthorizedActions["read"],
  transformationType: AuthorizedActions["read"],
  integrationType: AuthorizedActions["read"],
};

const Access: AccessList = {
  [SUPER_ADMIN]: {
    resources: {
      application: AuthorizedActions["admin"],
      configuration: AuthorizedActions["admin"],
      polling: AuthorizedActions["admin"],
      connection: AuthorizedActions["admin"],
      incomingHttpCredentials: AuthorizedActions["admin"],
      integrationConfiguration: AuthorizedActions["admin"],
      user: AuthorizedActions["admin"],
      tracking: AuthorizedActions["admin"],
      migration: AuthorizedActions["admin"],
      softlock: AuthorizedActions["admin"],
      ...settings,
    },
    menu: ["All"],
  },
  [ADMIN_ROLE]: {
    resources: {
      application: AuthorizedActions["admin"],
      configuration: AuthorizedActions["admin"],
      polling: AuthorizedActions["admin"],
      connection: AuthorizedActions["admin"],
      incomingHttpCredentials: AuthorizedActions["admin"],
      integrationConfiguration: AuthorizedActions["admin"],
      user: AuthorizedActions["read"],
      tracking: AuthorizedActions["admin"],
      migration: AuthorizedActions["admin"],
      softlock: AuthorizedActions["admin"],
      ...settings,
    },
    menu: ["All"],
  },
  [OPERATOR_TRACKING_ROLE]: {
    resources: {
      application: AuthorizedActions["read"],
      configuration: AuthorizedActions["read"],
      polling: AuthorizedActions["read"],
      connection: AuthorizedActions["read"],
      incomingHttpCredentials: AuthorizedActions["read"],
      integrationConfiguration: AuthorizedActions["read"],
      user: AuthorizedActions["read"],
      tracking: AuthorizedActions["operator"],
      migration: AuthorizedActions["read"],
      softlock: AuthorizedActions["read"],
      ...settings,
    },
    menu: ["All"],
  },
  [READONLY_ROLE]: {
    resources: {
      application: AuthorizedActions["read"],
      configuration: AuthorizedActions["read"],
      polling: AuthorizedActions["read"],
      connection: AuthorizedActions["read"],
      incomingHttpCredentials: AuthorizedActions["read"],
      integrationConfiguration: AuthorizedActions["read"],
      user: AuthorizedActions["read"],
      tracking: AuthorizedActions["read"],
      softlock: AuthorizedActions["read"],
      ...settings,
    },
    menu: ["configuration", "tracking"],
  },
};

function filterManagedRoles(roles: string[]) {
  return roles.filter((value) => value in Access);
}

/**
 * Grant access to resources depending of the user role
 *
 * @param {string[]} roles - Roles assumed by the user
 * @param {string} resource - Resource name
 * @returns {boolean} - True for acces granted or false for access denied to the resource
 */
export function grantAccess(roles: string[], resource: string): boolean {
  return filterManagedRoles(roles).some((role) =>
    ["All", resource].some((r) => Object.keys(Access[role]["resources"]).includes(r))
  );
}

export function accessMenu(roles: string[], resource: string): boolean {
  return filterManagedRoles(roles).some((role) =>
    ["All", resource].some((r) => Access[role]["menu"].includes(r))
  );
}

export function authorizeAction(roles: string[], resource: string, action: string): boolean {
  return filterManagedRoles(roles).some((role) =>
    ["All", action].some((a) => {
      return (
        Object.keys(Access[role]["resources"]).includes(resource) &&
        Access[role]["resources"][resource].includes(a)
      );
    })
  );
}

export function authorizeDownload(roles: string[], applications: string[]): boolean {
  let actionOk = authorizeAction(roles, "tracking", "download");
  let applicationOk = [SUPER_ADMIN, ADMIN_ROLE, ...applications].some((app) => roles.includes(app));
  return actionOk && applicationOk;
}

export function getAuthorizedPage(
  permissions: string[],
  { list, show, create, edit, icon }: Model
) {
  if (!permissions) return { icon };
  const readonly = { list, show };
  const admin = { list, show, create, edit };

  const pages = permissions.some((permissions) => permissions.includes("Admin")) ? admin : readonly;

  return {
    ...pages,
    icon,
  };
}
