import { downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";
import { dropDuplicates, removeEmptyOrNullString } from "@/Tools/helpers";
import { getConnections, getUsers, cleanText } from "@/Tools/exporterTools";

const ConfigurationExporter = async (records: any) => {
  const connectionIds = dropDuplicates(
    removeEmptyOrNullString(
      [
        ...records.map((record: any) => record.source.connection),
        ...records.map((record: any) => record.targets.map((target: any) => target.connection)),
        ...records.map((record: any) =>
          record.targets.map((target: any) => {
            if (target.integration && target.integration.parameters) {
              return target.integration.parameters.connection;
            }
            return "";
          })
        ),
      ].flat()
    )
  );

  const userIds = dropDuplicates(
    removeEmptyOrNullString([
      ...records.map((record: any) => record.created_by),
      ...records.map((record: any) => record.updated_by),
    ])
  );

  const connections = await getConnections(connectionIds);
  const users = await getUsers(userIds);
  const data = records.map((record: any) => {
    return {
      ...record,
      description: cleanText(record.description),
      created_by: users[record.created_by] ?? record.created_by,
      updated_by: users[record.updated_by] ?? record.updated_by,
      source: {
        ...record.source,
        description: cleanText(record.source.description),
        connection: connections[record.source.connection],
      },
      targets: record.targets.map((target: any) => {
        return {
          ...target,
          description: cleanText(target.description),
          connection: connections[target.connection],
          integration: {
            ...target.integration,
            ...(target.integration &&
              target.integration.parameters && {
                parameters: {
                  ...target.integration.parameters,
                  connection: connections[target.integration.parameters.connection],
                },
              }),
          },
        };
      }),
    };
  });

  return jsonExport(data, { rowDelimiter: "," }, (error: any, csv: any) => {
    if (error) console.log("Error CSV Export : ", error);
    downloadCSV(csv, `configurations_export_${Date.now()}`);
  });
};

export default ConfigurationExporter;
