import { Button, ButtonProps } from "react-admin";
import { ReactElement } from "react";
import { Record } from "ra-core";
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import {useHistory} from "react-router-dom";


interface Props {
    basePath?: string;
    icon?: ReactElement;
    label?: string;
    record?: Record;
    scrollToTop?: boolean;
}

type OpenWindowButtonProps = Props & ButtonProps;

const SimulerAction = (props: OpenWindowButtonProps) => {
    const { icon = <OpenInBrowserIcon />, label = "Simuler", record, ...rest } = props;
    let history = useHistory();

    const openWindow = () => {
        const url = "" + rest?.basePath  + "/" + record?.id + "/simuler";
        history.push(url);
    }

    return (
        <Button label={label} onClick={() => openWindow()}>
            {icon}
        </Button>
    );
};

export default SimulerAction;
