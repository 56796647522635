import {
    BooleanField,
    Datagrid,
    List,
    ReferenceField,
    ShowButton,
    TextField,
    useTranslate,
    Show
} from "react-admin";
import OpenWindowButton from "@/Components/CustomOpenWindowAction/OpenWindowAction";
import CustomPagination from "@/Components/CustomPagination";
import {ListBulkActionButtons, ShowActionsTopToolbar} from "@/Components/Models";

const ShowForm = (props: any) => {
    const translate = useTranslate();
    return (
        <Show actions={<ShowActionsTopToolbar />} {...props}>
        <List
            {...props}
            pagination={<CustomPagination />}
            bulkActionButtons={<ListBulkActionButtons />}
            syncWithLocation={false}
        >

                <Datagrid>
                    <TextField source="name" />
                    {props.children}
                    <ShowButton />
                    <TextField source="code" />
                    <TextField source="source.application" label={translate("resources.configuration.fields.source_application")}/>
                    <TextField source="source.path" label={translate("resources.configuration.fields.source_path")}/>
                    <TextField source="source.regex" />
                    <ReferenceField source="criticity" reference="criticityType" link="show">
                        <TextField source="name" />
                    </ReferenceField>
                    <BooleanField source="enabled" />
                    <BooleanField source="direct" />
                    <ReferenceField
                        source="source.connection"
                        reference="connection"
                        label={translate("resources.configuration.fields.source_connection_list")}
                        link="show"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <BooleanField
                        source="source.enabled"
                        label={translate("resources.configuration.fields.source_enabled_list")}
                    />
                    <ReferenceField
                        source="targets[0].connection"
                        reference="connection"
                        label={translate("resources.configuration.fields.target_connection_list")}
                        link="show"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField source="targets[0].application" label={translate("resources.configuration.fields.target_application")}/>
                    <TextField source="targets[0].path" label={translate("resources.configuration.fields.target_path")}/>
                    <BooleanField
                        source="targets[0].enabled"
                        label={translate("resources.configuration.fields.target_enabled_list")}
                    />
                    <OpenWindowButton />
                </Datagrid>

        </List>
        </Show>
)};
export default ShowForm;
