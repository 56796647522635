import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  SimpleList,
  EditButton,
  useTranslate,
} from "react-admin";
import { useMediaQuery } from "@material-ui/core";
import strip from "@/Tools/stripHtml";
import {
  ListBulkActionButtons,
  useEditNeedActions,
  useNeedCopyWindowActions,
  useSearchFilters,
} from "..";
import SearchInput from "@/Components/Layout/SearchInput";
import { ReactElement } from "react";
import CopyIcon from "@material-ui/icons/FileCopy";
import CopyButton from "@/Components/Models/Configuration/CopyButton";
import { authorizeAction } from "@/Settings/roles";
import BaseExporter from "@/Components/Models/BaseModel/BaseExporter";
import ConfigurationExporter from "@/Components/Models/Configuration/Exporter";
import PollingExporter from "@/Components/Models/Polling/Exporter";
import IntegrationExporter from "@/Components/Models/IntegrationConfiguration/Exporter";

const ListPanel = (props: any) => (
  <div dangerouslySetInnerHTML={{ __html: props.record.description }} />
);

const useFilters = (filters: ReactElement[] = []) => {
  const translate = useTranslate();
  return [
    <SearchInput source="name" label={translate("search.filter.name")} />,
    <SearchInput source="description" label={translate("search.filter.description")} />,
    ...filters,
  ];
};

const usePanel = (props: any) => {
  if (props.resource === "user") return undefined;
  return props.listpanel ?? <ListPanel {...props} />;
};

function getCustomExporter(resource: string) {
  if (resource === "configuration") {
    return ConfigurationExporter;
  }
  if (resource === "polling") {
    return PollingExporter;
  }
  if (resource === "integrationConfiguration") {
    return IntegrationExporter;
  }
  return BaseExporter;
}

const ListForm = (props: any) => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const editNeedActions =
    useEditNeedActions(props.resource) &&
    authorizeAction(props.options.roles, props.resource, "edit");
  const copyNeedActions =
    useNeedCopyWindowActions(props.resource) &&
    authorizeAction(props.options.roles, props.resource, "copy");

  const filters = useFilters(props.filters);
  const panel = usePanel(props);
  const { icon = <CopyIcon /> } = props;
  const CustomExporter = getCustomExporter(props.resource);
  console.log("Filters : ", props.filters);
  return (
    <List
      {...props}
      exporter={CustomExporter}
      pagination={false}
      bulkActionButtons={<ListBulkActionButtons roles={props.options.roles} />}
      syncWithLocation={false}
      filters={useSearchFilters(props.resource) ? filters : []}
      sort={{ field: "name", order: "ASC" }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.name}
          secondaryText={(record) => strip(record.description)}
        />
      ) : (
        <Datagrid expand={panel}>
          <TextField source="name" />
          {props.children}
          <ShowButton />
          {editNeedActions && <EditButton />}
          {copyNeedActions && <CopyButton label="Copy" icon={icon} />}
        </Datagrid>
      )}
    </List>
  );
};

export default ListForm;
