import { TranslationMessages } from "react-admin";
import frenchMessages from "ra-language-french";

const customFrenchMessages: TranslationMessages = {
  ...frenchMessages,
  pos: {
    search: "Rechercher",
    settings: "Paramètres",
    language: "Langue",
    theme: {
      name: "Theme",
      light: "Clair",
      dark: "Obscur",
    },
    dashboard: {
      welcome: {
        title: "Bienvenue dans l'administration de Arkema MFT",
        subtitle: "Utilisez le menu à gauche pour naviguer dans l'administration.",
      },
    },
    menu: {
      sales: "Ventes",
      catalog: "Catalogue",
      customers: "Clients",
    },
  },
  common: {
    actions: {
      enable: {
        "btn.enabled": "Activé",
        "btn.disabled": "Désactivé",
        title: "Valider",
        content: "Êtes-vous sûr de vouloir changer le statut pour ces éléments?",
        success: "Statut changé pour les %{resource}",
        failure: "Échec du changement de statut pour les %{resource}",
      },
      copyToEnv: {
        success: "Copy successful for %{resource} items",
        failure: "Copy failed : %{error}",
      },
      explore: {
        name: "Nom",
        size: "Taille",
        type: "Type",
        modifiedAt: "Dernière modification"
      },
      test: {
        label: "Test",
        success:
          "Test validé pour %{id} test connectivité %{connectivityTest} et l'échange de donnée est %{listingFile} ",
        failure: "Erreur lors du test pour %{id} (%{error})",
        timeout: "Timeout de la requête après 30 secondes",
      },
      copy: "Copier",
      yes: "Oui",
      no: "Non",
    },
  },
  search: {
    filter: {
      name: "Nom",
      description: "Description",
      code: "Code",
      enabled: "Activé",
    },
  },
  resources: {
    userRolesType: {
      name: "Roles User",
      fields: {
        name: "Nom du role",
        description: "Description",
      },
    },
    connectionType: {
      name: "Type de connexion",
      fields: {
        name: "Nom",
        description: "Description",
      },
    },
    transfertType: {
      name: "Transfert Type",
      fields: {
        name: "Nom",
        description: "Description",
      },
    },
    transfertMode: {
      name: "Transfert Mode",
      fields: {
        name: "Nom",
        description: "Description",
      },
    },
    criticityType: {
      name: "Criticité Type",
      fields: {
        name: "Nom",
        description: "Description",
      },
    },
    pollingType: {
      name: "Polling Type",
      fields: {
        name: "Nom",
        description: "Description",
      },
    },
    fileNamePattern: {
      name: "File Name Pattern",
      fields: {
        name: "Nom",
        description: "Description",
        pattern_rule: "Pattern",
        trim_character: "Caractère de trim",
        new_name: "Nouveau nom",
      },
    },
    transformationType: {
      name: "Transformation Type",
      fields: {
        name: "Nom",
        description: "Description",
        parameters: "Paramètres",
      },
    },
    integrationType: {
      name: "Intégration Type",
      fields: {
        name: "Nom",
        description: "Description",
        command: "Commande",
        default_parameters: "Paramètres par défaut",
        default_path: "Path par défaut",
      },
    },
    application: {
      name: "Application",
      fields: {
        name: "Nom",
        description: "Description",
      },
      deleteActions: {
        allDeleted: "Toutes les applications sélectionnées sont supprimées",
        unDeleted:
          "%{deletedCount} applications supprimées, %{undeletedCount} applications ignorées (associées à des configurations)",
        error: "Erreur lors de la suppression des applications",
        deleteLabel: "SUPPRIMER",
        deletingLabel: "SUPPRESSION...",
      },
    },
    connection: {
      name: "Connexion",
      fields: {
        name: "Nom",
        description: "Description",
        host: "Host",
        type: "Type",
        user: "Utilisateur",
        password: "Mot de passe",
        reject_unauthorized: "Rejeter les certificats non valides",
        privateKey: "Clé privée",
        passphrase: "Phrase secrète",
        port: "Port",
        bucketName: "S3 Bucket",
        roleARN: "Role ARN",
        roleSessionDurationInSec: "Durée de session de la session de role",
        region: "AWS Région",
        enabled: "Activé",
        created_by: "Crée par",
        created_at: "Crée le",
        updated_by: "Mis à jour par",
        updated_at: "Mis à jour le",
        maintenance_enabled: "Tous les maintenances activées",
        mainetance_start_schedule_expression: "Expression Schedule de début",
        mainetance_end_schedule_expression: "Expression Schedule de fin",
        maintenance_note_utc:
          "Noe: Les expressions Cron doivent être projeté sur des horaires en UTC",
        transfert_path: "MOVE_AFTER path",
      },
      deleteActions: {
        allDeleted: "Toutes les connexions sélectionnées sont supprimées",
        unDeleted:
          "%{deletedCount} connexions supprimées, %{undeletedCount} connexions ignorées (associées à des configurations)",
        error: "Erreur lors de la suppression des connexions",
        deleteLabel: "SUPPRIMER",
        deletingLabel: "SUPPRESSION...",
      },
      errors: {
        privateKey_or_password_required: "Clé privée ou mot de passe requis",
      },
    },
    user: {
      name: "User",
      fields: {
        name: "Nom",
        email: "Email",
        roles: "Roles actuels",
        updateRoles: "Nouveaux Roles",
      },
      edit: {
        roleLengthError: "Attribuer au maximum un (1) role",
      },
    },
    migration: {
      name: "Migration",
    },
    tracking: {
      name: "Console de suivi",
      actions: {
        actionSuccess: "Opeation réussie",
        actionError: "Opeation en échec",
        actionNoSeletion: "Aucun fichier séléctionné",
      },
      files: {
        action: "Action",
        fileName: "Nom de fichier",
        status: "Statut MFT",
        executionStartDate: "Date de début d’exécution",
        executionEndDate: "Date de fin d’exécution",
        errorDetails: "Details de l’erreur",
        accessLogs: "Accès aux logs",
        targetName: "Nom de la Cible",
        targetConnexionName: "Nom de connexion de la Cible",
        allFilesRetrieved: "Toutes les executions de fichiers ont été récupérées",
        downloadFile: "Télécharger le fichier",
      },
      bulks: {
        resetButton: "Reinitialiser",
        abandonmentButton: "Abondonner",
        retryButton: "Relance",
      },
      searchForm: {
        all: "Tous",
        configurationName: "Nom de flux",
        configurationCode: "Code flux",
        issuingApplication: "Application émettrice",
        receivingApplication: "Application réceptrice",
        issuingConnection: "Connection émettrice",
        receivingConnection: "Connection réceptrice",
        executionStartDate: "Date de début d’exécution",
        executionEndDate: "Date de fin d’exécution",
        flowStatus: "Statut de flux",
        submitButton: "Chercher",
        flowStatusOptions: {
          all: "Tous",
          completed: "Terminé",
          progress: "En cours",
          error: "En erreur",
          abandoned: "Abandonné",
          cancelled: "Annulé",
          allExceptCompleted: "Tous sauf terminé",
        },
        results: {
          configurationName: "Nom",
          configurationCode: "Code",
          editExecution: "Modifier",
        },
      },
    },
    incomingHttpCredentials: {
      name: "Incoming HTTP Credentials",
      fields: {
        name: "Nom",
        username: "Utilisateur",
        password: "Mot de passe",
        authType: "Type d'auth",
        description: "Description",
        created_by: "Créé par",
        created_at: "Créé le",
        updated_by: "Mis à jour par",
        updated_at: "Mis à jour le",
        passwordError:
          "Le mot de passe doit faire au moins 10 caractères et inclure au moins 1 majuscule, 1 minuscule, 1 caractère spécial (parmis [!@#$%^&*()_\\-+=] ) et 1 chiffre.",
      },
    },
    softlock: {
      name: "Softlock",
      fields: {
        id: "ID",
        mode: "Mode",
        start_date: "Date de début",
      },
    },
    polling: {
      name: "Polling",
      fields: {
        name: "Nom",
        description: "Description",
        enabled: "Activé",
        connection: "Connection",
        schedule_expression: "Schedule Expression",
        regex: "Regex",
        path: "Path",
        created_by: "Crée par",
        created_at: "Crée le",
        updated_by: "Mis à jour par",
        updated_at: "Mis à jour le",
        connection_list: "Connection",
      },
    },
    integrationConfiguration: {
      name: "Intégration",
      fields: {
        name: "Nom",
        description: "Description",
        type: "Intégration Type",
        connection: "Connexion",
        parameters: "Paramètres",
        path: "Chemin",
        created_by: "Crée par",
        created_at: "Crée le",
        updated_by: "Mis à jour par",
        updated_at: "Mis à jour le",
      },
    },
    configuration: {
      name: "Configuration",
      fields: {
        name: "Nom",
        code: "Code",
        direct: "Direct",
        description: "Description",
        criticity: "Criticité",
        requestor: "Demandeur",
        demand_number: "Numéro de demande",
        enabled: "Activé",
        created_by: "Crée par",
        created_at: "Crée le",
        updated_by: "Mis à jour par",
        retry: "Rejeu",
        retry_auto_frequence: "Fréquence de rejeu",
        retry_auto_ecart: "Espace en millisecondes",
        updated_at: "Mis à jour le",
        transformation: "Transformation",
        "source.use_s3_deposit": "Utiliser le dépot sur S3",
        "source.description": "Source Description",
        "source.connection": "Connection",
        "source.enabled": "Activé",
        "source.wisp_group": "Groupe WISP",
        "source.path": "Path",
        "source.application": "Application",
        "source.regex": "Regex",
        "source.transfert_type": "Transfert Type",
        "source.transfert_mode": "Transfert Mode",
        "source.nb_files_per_transfert": "Nb de fichiers par transfert",
        "source.schedule_expression": "Schedule Expression",
        "source.polling": "Polling Type",
        "source.transformations": "Transformations",
        "source.job": "Job",
        "source.information": "Information",
        "source.transfert_path": "Transfert Path (uniquement pour MOVE_AFTER)",
        source_connection_list: "Source - Connection",
        source_enabled_list: "Source - Activé",
        source_application: "Source - Application",
        source_path: "Source - Path",
        source_filters: "Filtres",
        source_filters_unit: "Unité",
        source_filters_unitType: "Type d'unité",
        source_filters_update_date_days: "Jours",
        source_filters_update_date_hours: "Heures",
        source_filters_update: "Filtrer les fichiers dont la date de modification est avant:",
        source_regex: "Source - Regex",
        "target.description": "Target Description",
        "target.wisp_group": "Groupe WISP",
        "target.connection": "Connection",
        "target.enabled": "Activé",
        "target.error_if_file_exists": "Retourne une erreur si le fichier existe déjà",
        "target.path": "Path",
        "target.application": "Application",
        "target.pattern_file_name": "Pattern File Name",
        "target.nb_files_per_transfert": "Nb de fichiers par transfert",
        "target.delay": "Délai de Transfert",
        "target.delayInfo": "Délai (en secondes)",
        "target.delayValue": "Valeur du délai",
        "target.schedule_expression": "Schedule Expression",
        "target.integration": "Integration",
        "target.integrationTemplate": "Modèle d'intégration",
        "target.integrationType": "Type d'intégration",
        "target.integrationPath": "Chemin de l'intégration",
        "target.integrationFilename": "Nom du fichier",
        "target.integrationContentPattern": "Modèle de contenu",
        "target.integrationConnection": "Connexion de l'intégration",
        "target.integrationUseCurrentConnection": "Utiliser la connexion actuelle de la target",
        "target.transfer_type": "Transfer type",
        "target.transformations": "Transformations",
        "target.autoDisabled": "Auto Disabled",
        target_connection_list: "Target - Connection",
        target_enabled_list: "Target - Activé",
        target_application: "Target - Application",
        target_path: "Target - Path",
        "target.job": "Job",
        "target.information": "Information",
        "target.new_name": "Nouveau nom",
        "target.trim_character": "Caractère de trim",
        filename_error: `Le nom du fichier ne doit pas contenir l'un des caractères suivant : <, >, :, |, ?, *, ", /, \\`,
      },
    },
  },
};

export default customFrenchMessages;
